/*----------------------Zoomboxar-------------------------------*/
.zoombox { height:20vw; overflow:hidden; position:relative;}
.zoombox_innerdiv {height:100%; width:100%; background-position: 50% 50%; background-size:cover; transform: scale(1); position:absolute; transition: transform 3s ease-in 0s;}
.zoomboxar > a:hover .zoombox_innerdiv {transform: scale(1.2); transition: transform 3s ease-in 0s;} 
.zoombox_textdiv {height:100%; width: 100%; overflow:hidden; margin:0;  padding: 0px 2em; text-align:center; position:absolute;} 

/*----------------------Fadeboxar-------------------------------*/
.fadebox {height:20vw; overflow:hidden; display:block; background-size:cover; background-position: center center;}
.fadebox_innerdiv {height:100%; background:rgba(0, 0, 0, 0.6 ); opacity:0; transition: opacity 0.5s ease-in 0s; } 
.fadebox:hover .fadebox_innerdiv {opacity:1;} 
.fadebox_textdiv {padding: 0 2em; color:#fff; text-align: center; position: relative; }

/*--------------------Mediaquerys fadeboxar--------------------*/
@media (max-width: 1300px) 
{
.fadebox, .zoombox {height:23vw}
}
@media (max-width: var(--breakpoint-m))
{
.fadebox_textdiv span, .zoombox_textdiv span {display:none;}
.fadeboxar.container, .zoomboxar.container, .fadeboxar.container.lrg, .zoomboxar.container.lrg {width: 100%;} 
}
@media (max-width: var(--breakpoint-s))
{
.fadebox, .zoombox {height:30vw;}
.fadebox_innerdiv, .zoombox_textdiv {background:rgba(0, 0, 0, 0.3); opacity:1; color:#fff; }
.zoomboxar > a:hover .zoombox_innerdiv, .zoombox_innderdiv {transform:none;}
}
@media (max-width: var(--breakpoint-xs))
{
.fadebox, .zoombox {height:50vw;}
}
