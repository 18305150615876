:root {
    --padding: 2%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 2em; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/theme/intendit/css/modules/fade_zoom.css";
@import "/cssmodules/animate.css";
@import "/fontmodules/Lato.css";
@import "/fontmodules/Hind.css";
/*
@import "/fontmodules/ABeeZee.css";
@import "/cssmodules/photoswipe.css";

*/

 

a.readmore.no-grow {
    color: #fff;
    background: #02112e;
    width: 100%;
}





.eef {background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;   height: auto;  padding: 10px;position: absolute;  right: 20px;
top: 95px;  width: 230px; z-index: 1; }




/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------GENERAL-----------------------------------------------------------------------*/
body {font-weight: 300; 
      color: #404040;
      line-height:1.4em;
      font-family: 'Hind', sans-serif;
      /*background: url('/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;*/ }
 
/*---------------------------------------------------------------DIVERSE "WRAPPERS"------------------------------------------------------------*/
.wrapper { min-height: 100vh;   } 
.startsida .wrapper {min-height:1em; }
.container {width:100%; margin: 0 auto; }
.container.lrg {max-width: 1300px;}
.sida {padding: 40px 0 50px; } 

/*---------------------------------------------------------------BASIC ELEMENTS----------------------------------------------------------------*/
a, a:hover {color:#404040; font-weight:300; text-decoration: none;}
p {margin:3px 0 20px;}
strong {font-weight:500;}
iframe {max-width:100%;}

.sida a {font-weight:400; color:#3692C2;  }

/*-------------------images--------------------*/
img {max-width:100%; height:auto;}
.bild {float:right; width:360px;  max-width:40%; height:auto;  margin: 0 0 30px 30px; overflow:hidden;}
.box_bild, .listing_bild {width:100%; flex:none; max-width:100%;} /*säkra vyn i IE*/
.bild img, .box_bild img, .listing_bild img, .galleri_bild img {width:100%; height:auto; } /*säkra vyn i IE*/
.bild span {font-style:italic; color:#606060;}
/*-------------------listor--------------------*/
.sida ul {list-style:disc; padding: 0 0 0 15px; }
.sida ul li {margin-bottom:3px;  }
/*.sida ul {list-style:none; padding: 0; }
.sida ul li:before {content: "\f00c"; margin:0 10px 0 0; font-family: FontAwesome;}*/

/*------------readmore & buttons----------------*/
.button, .readmore, .boltforms-row button {background: rgba(0, 0, 0, 0.15); padding: 6px 0 8px; display:block; margin:0; text-align: center; border-radius:2px; line-height: 1.2em; border:none; transition:background 0.5s ease-in 0s;}
.readmore { width:100px;} .button, .boltforms-row button  { width:100%;}  
.button:hover, .readmore:hover, .boltforms-row button:hover {background:rgba(0, 0, 0, 0.3); transition:background 0.5s ease-in 0s;}
.boltforms-row button, .boltforms-row button:hover { color:#fff; }
button.slick-arrow {background:none;}

.box .button {color:#fff; }
/*-----------------ikoner-----------------------*/
.fa.round {border-radius: 50%; padding: 11px 0 0; text-align: center; width: 45px; height: 45px; font-size:1.5em;background:rgba(0, 0, 0, 0.15);}
.fa.circle {border-radius: 50%; padding: 12px 0 0; text-align:center; width: 50px; height: 50px; font-size:1.5em; border: 1px solid #fff; color:#fff; display:block;}

.toptext .fa.round { padding: 13px 0 0; margin: 0 0 6px 6px; background: rgba(0, 0, 0, 0.5); color: #ffffff; height: 50px; width: 50px; /*clear:both; display:block;*/}
.sidfot .fa.round {background:rgba(255, 255, 255, 0.15); font-size:1.3em; width: 40px; height: 40px; padding: 11px 0 0;  }
.fadebox_textdiv .fa.circle { clear:both; margin: 10px auto 0; }
.box .fa {display:block; margin-bottom:15px; text-align:center; }

/*-------------egna klasser---------------------*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3) }
.shadowborder {box-shadow: 0px 0px 0px 1px #e4e4e4;}
.pubdatum {color:#909090; font-size:95%; display:block; clear:both;  }
.record {border-bottom:1px solid #e4e4e4; }

/*---------------------------------------------------------------FONTS------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {margin: 0; letter-spacing:0; line-height:1.1em; padding: 0px; font-weight:600;  }
h1{font-size: 2.2em;padding: 0px 0 20px; /*text-transform:uppercase;*/}
h2{font-size: 1.7em;}
h3{font-size: 1.3em;}
h4{font-size: 1em;}
h5{font-size: 1em;}

h2.highlight {font-size:4em; line-height:1.2em; padding: 20px 0 ; color:#e4e4e4; font-weight:300; }
.sida.listing h1 {padding-bottom:40px; margin-bottom:40px; border-bottom:1px solid #e4e4e4;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------STARTSIDAN various------------------------------------------------------------------------*/ 
.toptext { position:absolute; right:10px; top:90px; z-index:1;  display:none;  } /*display none by default*/
.topbild {height:45vh; max-height:45vw; background-position: center center; background-size:cover; position: relative;  border-bottom: 10px solid #e4e4e4; }

/*----------------------Bildspel-----------------------*/
.slick-slider {margin:0 auto; position: relative; border-bottom: 10px solid #e4e4e4;   }
.slick-slide {background-size:cover; background-position: center center; min-height:70vh; display:flex !important; background-repeat: no-repeat;  }   
  
 
/*----------------------Startsidatext-----------------------*/
.startsidatext {    padding: 80px 0 120px;width: 100%;}
.startsidatext .innerdiv  {  padding: 40px; width: 100%; background: rgba(255, 255, 255, 0.7) ;  }
.startsidatext_textdiv {font-size:1.7em; line-height:1.1em; font-weight: 400;   margin: 0 auto; max-width: 1100px;} 
.startsidatext_textdiv h1 {border-bottom: 11px solid #3669cc; font-size: 2.2em; margin: 0 0 10px; padding: 0 0 10px; }


/*----------------------Boxar-------------------------------*/
.boxar {padding: 0px 0 20px;  margin-top:-100px; }
.box {position:relative;  color:#fff; }
.box h2 { text-transform:uppercase;   }

.box:nth-child(1) {background:#174AAD;}
.box:nth-child(2) {background:#3669CC;}
.box:nth-child(3) {background:#002588;}



.godatitel h2 {font-size:2.2em; color:#174AAD; }
.godatitel {  padding: 40px 0 0; background: url('/theme/intendit/graphics/bg.png') center top repeat ; border-top: 10px solid #d0d0d0; }
.godaexempel {padding:20px 0 30px; margin-top:0; background:#e4e4e4;  background: url('/theme/intendit/graphics/bg.png') center top repeat ;}
.exempel {background:#fff; color:#404040; box-shadow: 0px 0px 0px 2px #d0d0d0;   }
.exempel h2 { border-bottom: 5px solid #3669cc; max-width:600px;  color: #3669cc; font-size: 1.55em; font-weight: 400; margin: 0 0 10px; padding: 0 0 10px; text-transform: none; }





/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*---------------------------------------------------------------GALLERI ------------------------------------------------------------------*/
.magnificgalleri, .wrapper .loggagalleri {padding: 30px 0 0; margin-top:10px; border-top: 1px solid #d0d0d0; }
.galleri_bild a {display:block; }
.galleri_bild {box-shadow: 1px 1px 0 0 #e4e4e4;} .galleri_bild:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.galleri_bild img {padding:20px;}
.thumbtitel {
    font-size: 100%;
    text-align: center;
}

/*logo gallery*/
.loggagalleri .logga {min-height: 70px; } 
.loggagalleri .logga div {text-align:center; width: 100%; flex:0 0 auto;} 
.loggagalleri .logga img {height:auto; }
.standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}   
.mfp-arrow, .mfp-arrow:before,.mfp-arrow:after {border:none;  height:100px; color:#fff; font-size:60px; font-family: FontAwesome; opacity:0.9; }
.mfp-arrow-left:before {content:"\f104"; } .mfp-arrow-right:before {content:"\f105"; }
button.mfp-close { font-size: 3.4em; margin:0 -2px 0 0; opacity: 0.9;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*--------------------------------------------------------------KONTAKT SAKER------------------------------------------------------------------*/
/*Kontaktbanner & Formulär*/
.boltforms-row label, .boltforms-row button {font-weight: 400; margin-bottom: 0;}
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060;  }

.kontaktbanner {background: #02112E; padding:50px 0; color:#fff; box-sizing: border-box;}
.kontaktbanner h2 { padding:0px 0 10px; }
.kontaktbanner .boltforms-row {float:left; width:33.3%; padding-right:10px;}
.kontaktformular .boltforms-row, .kontaktbanner .boltforms-row:nth-child(n+4) {width:100%;}

/*Googlekarta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}

/*Sifoten*/
.sidfot {padding: 50px 0 200px; width:100%; background:#000;}
.sidfot, .sidfot a {color:#9f9f9f; font-weight:300; transition:color 0.5s ease-in 0s;}
.sidfot h3 {padding: 0 0 5px;} 
.sidfot a:hover {color:#fff;  transition:color 0.5s ease-in 0s;}

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
header {text-transform:none; width:100%; background:#02112E; z-index:10; position:relative;   min-height:75px;  }
body.scrolled header {}

/*Logotype*/
.logo {  padding: 16px 20px 13px; box-sizing:border-box; background:#fff; position:absolute; top:0; left:50px;  } 
.logo img {max-height:60px; width:auto; }
.logo a {padding:0; display:block;}  

/*Standard meny + hovers*/
nav {background:inherit; } 
header nav ul.menu > li {padding: 0 10px; margin:0; border-right:1px solid #192742;}
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 5px 2px; border-bottom: 2px solid rgba(0, 0, 0, 0); margin:0; letter-spacing:0; color:#e4e4e4;  transition:color 0.5s ease-in 0s; }
nav ul.menu li a:hover, nav ul.menu li.active a { color:#fff; transition:color 0.5s ease-in 0s;border-bottom: 2px solid #fff; }
nav ul.menu li.dropdown > a:after { content: " \f107"; display: inline-block; font-family:FontAwesome; font-size: 1em; margin: 0 0 0 5px; vertical-align: middle;}
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }

/*Mobile menu under 800px - fixed header*/
.mobile-menu header {position:fixed; top:0; min-height:80px; box-shadow:none; flex-wrap:nowrap;background:#fff;  border-bottom: 1px solid #e4e4e4;} /*background på header bar*/
.mobile-menu .wrapper {margin-top:80px;}  /*knuffar ner innehåll bakom menyn - justera höjd*/
.mobile-menu .logo {padding:10px 20px 10px 20px; box-sizing:border-box; background:none; position:relative; top:auto; left:auto;  } 
.mobile-menu nav ul.menu {margin-top:1px; background:rgba(255, 255, 255, 0.95); }
.mobile-menu nav ul.menu.show { max-height:calc(100vh - 80px); }  /*räknar höjd - fixed header*/
.mobile-menu nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
.mobile-menu nav .menu .dropdown ul {padding:0; left:0px;} 
.mobile-menu nav ul.menu li.dropdown > a:after {display:none; }
nav ul.menu > li:last-child > ul { left: auto;right: 0;} 
.mobile-menu nav ul.menu li a {   color:#404040;  }

nav #menu-icon {padding: 20px 20px 20px 0;}
nav #menu-icon span {border-radius:3px; border: 2px solid #02112E;}

 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}    
}


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
.slick-slide { min-height:55vw;  }   
.startsidatext_textdiv {
    font-size: 1.4em;}
    
    .startsidatext .innerdiv { padding: 20px; } 
    
/*Containers*/
.container, .container.lrg {width: 98%;}  
header.container, header.container.lrg {width: 100%;}  
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
/*Fontsize*/ 
body { line-height:1.25em; font-size: 0.97em; }
    
.startsidatext_textdiv { font-size: 1.35em;}
    
        .startsidatext {
        padding: 105px 0 120px;}
    
    
/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 0 1px 0 0 #e4e4e4;}
.galleri_bild:nth-child(5n+5) {box-shadow: 1px 1px 0 0 #e4e4e4;}  
    
/*Bild*/
.bild { margin: 0 0 20px 20px;  }      
   
/*Sidfot*/   
.sidfot .s-twelve {border-bottom: 1px solid #222222;margin: 0 auto;max-width: 400px; padding: 20px 0;text-align: center; }
.sidfot .s-twelve:last-child {border-bottom: none; }   
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
  .startsidatext_textdiv {
      font-size: 1.2em;    }
    
    

        
        
    
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.kontaktbanner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 1px 1px 0 0 #e4e4e4;} 
.galleri_bild:nth-child(2n+2) {box-shadow: 0 1px 0 0 #e4e4e4;} 
.galleri_bild img {padding: 13px;}    
    
/*map*/
.map-canvas {width:100%; max-height:200px; }
    
/*table varje cell under varann med border efter varje rad 
table tr td {display: list-item; list-style: none; padding:0; }
table tr td:first-child {padding: 10px 0 0;}
table tr td:last-child {padding: 0 0 10px;}*/     
}

@media (max-width: 350px)
{
/*bild*/
.bild {width:100%; max-width:100%; margin: 0 0 10px; float:none; }     
.bild img {width:100%; height:auto;}    
}

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/




 

/* --------------------------------------------------------Sliding border under text--------------------------------------------------------- 
.box h2 {padding-bottom: 35px; position: relative; text-align: center;}
.box h2:after {content:""; position:absolute; background:#404040; height:3px; width:20px; margin: 45px 0 0; left:50%; transition: width 0.5s ease-in 0s; transition: margin 0.5s ease-in 0s; }
.box:hover h2:after { width:150px;  margin:50px 0 0 -75px; }*/

/* ------------------------------------------------------------Tables--------------------------------------------------------------------------
table { margin:30px 0; font-size:95%; }
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr td {margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; }
table tr td:last-child {padding:5px 0px 5px 0;}

/* ------------------------------------------------------------Sökning--------------------------------------------------------------------------
.searchbox {}
#searchform {margin:0; }
#searchform input {margin:0; height: 23px; padding: 3px 10px; color:#959595; display: inline-block; }
.search {float: none;padding: 0;}*/

/* ------------------------------------------------------------Egen menu -icon-----------------------------------------------------------------
#menu-icon span {border:none; }
#menu-icon  {background-image: url("/theme/intendit/icons/mobile-menu-icon.png"); background-position: center right; background-repeat: no-repeat;
background-size: 100% auto; height: 30px; width: 40px;}*/


/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}

.slick, .slide {
min-height: 70vh;
}
